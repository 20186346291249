import {
    Button,
    ClickAwayListener,
    createMuiTheme,
    Grid,
    Grow,
    MenuItem,
    Paper,
    Popper,
    Theme,
    ThemeProvider,
    useMediaQuery,
} from '@material-ui/core'
import { KeyboardArrowDown, Translate } from '@material-ui/icons'
import {
    changeLocale,
    FormattedMessage,
    IntlShape,
    useIntl,
} from 'gatsby-plugin-intl'
import React, { Ref, useEffect, useRef, useState } from 'react'
import { PLANLY_THEME } from '../../../Theme/Theme'
import { StyledMenuList } from '../../styles'

const LanguageDropdown: React.FC = () => {
    const theme: Theme = createMuiTheme({
        ...PLANLY_THEME,
    })
    // hooks
    const anchorRef: Ref<HTMLButtonElement> = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)
    const intl: IntlShape = useIntl()
    const isExtraSmall: boolean = useMediaQuery(theme.breakpoints.down('xs'))

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: React.MouseEvent<EventTarget>) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return
        }

        setOpen(false)
    }

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault()
            setOpen(false)
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open)
    useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current!.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <Grid container alignItems="flex-end" justify="flex-end">
            <Grid item>
                <ThemeProvider theme={theme}>
                    <Button
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="default"
                        variant="outlined"
                        startIcon={!isExtraSmall ? <Translate /> : null}
                        endIcon={<KeyboardArrowDown />}
                    >
                        <Grid
                            container
                            alignItems="flex-end"
                            justify="flex-end"
                        >
                            {isExtraSmall ? (
                                intl.locale.toUpperCase()
                            ) : (
                                <FormattedMessage
                                    id={`languageDropdown.${intl.locale}`}
                                />
                            )}
                        </Grid>
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom'
                                            ? 'center top'
                                            : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener
                                        onClickAway={handleClose}
                                    >
                                        <StyledMenuList
                                            autoFocusItem={open}
                                            id="menu-list-grow"
                                            onKeyDown={handleListKeyDown}
                                        >
                                            <MenuItem
                                                onClick={(event) => {
                                                    changeLocale('en')
                                                    handleClose(event)
                                                }}
                                            >
                                                <FormattedMessage id="languageDropdown.en" />
                                            </MenuItem>
                                            <MenuItem
                                                onClick={(event) => {
                                                    changeLocale('nl')
                                                    handleClose(event)
                                                }}
                                            >
                                                <FormattedMessage id="languageDropdown.nl" />
                                            </MenuItem>
                                        </StyledMenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ThemeProvider>
            </Grid>
        </Grid>
    )
}

export default LanguageDropdown
