import {
    Box,
    Container,
    Grid,
    Hidden,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { FormattedMessage } from 'gatsby-plugin-intl'
import React from 'react'
import { scroller } from 'react-scroll'
import styled from 'styled-components'
import AnimatedWave from '../../AnimatedWave/AnimatedWave'
import CtaButton, { ButtonVariants } from '../../Button/CtaButton'
import { StyledHero } from '../styles'
import HeroImage, { HeroImageProps } from './HeroImage/HeroImage'

export type HeroProps = {
    title: string
    description: string
    description2?: string
    functionalities: string[]
    cta: CtaProps[]
    image?: HeroImageProps
}

export type CtaProps = {
    type: ButtonVariants
    id: string
    label: string
    scrollTo?: string
    link?: string
}

declare global {
    interface Window {
        gtag: Function
    }
}

const StyledContainer = styled(Container)`
    z-index: 1;
    margin-bottom: 4rem;
`
const StyledWhiteTextBox = styled(Box)`
    color: rgba(255, 255, 255, 0.85);
`

const Hero: React.FC<HeroProps> = ({
    title,
    description,
    description2,
    functionalities,
    cta,
    image,
}: HeroProps) => {
    const theme: Theme = useTheme()
    const isExtraSmall: boolean = useMediaQuery(theme.breakpoints.down('xs'))
    const isSmall: boolean = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <StyledHero
            className={description2 !== undefined ? 'hero-large' : 'hero'}
        >
            <StyledContainer>
                <Grid
                    container
                    justify="flex-start"
                    direction="row"
                    alignItems={image ? 'flex-start' : 'center'}
                >
                    <Hidden smUp>
                        <Grid item xs={12}>
                            <StyledWhiteTextBox mb={2}>
                                <Typography
                                    variant="h3"
                                    component="h1"
                                    gutterBottom
                                >
                                    <FormattedMessage id={title} />
                                </Typography>

                                <Typography variant="body1">
                                    <FormattedMessage id={description} />
                                </Typography>
                            </StyledWhiteTextBox>
                        </Grid>
                    </Hidden>

                    <Grid sm={12} md={image ? 7 : 8} item>
                        <StyledWhiteTextBox py={isSmall ? 0 : 3}>
                            <Hidden xsDown>
                                <Box px={3}>
                                    <Typography variant="h1" gutterBottom>
                                        <FormattedMessage id={title} />
                                    </Typography>

                                    <Typography variant="subtitle1">
                                        <FormattedMessage id={description} />
                                    </Typography>
                                </Box>
                                <Box px={3}>
                                    <ul>
                                        {functionalities.map((f) => (
                                            <li key={f}>
                                                <Typography variant="subtitle1">
                                                    <FormattedMessage id={f} />
                                                </Typography>
                                            </li>
                                        ))}
                                    </ul>
                                </Box>
                                {description2 ? (
                                    <Box px={3}>
                                        <Typography variant="subtitle1">
                                            <FormattedMessage
                                                id={description2}
                                            />
                                        </Typography>
                                    </Box>
                                ) : null}
                            </Hidden>
                            <Box mt={4}>
                                <Grid
                                    container
                                    justify="flex-start"
                                    alignItems="center"
                                    direction="row"
                                >
                                    <Box component="span" m={1}>
                                        {cta.map(
                                            ({ id, type, scrollTo, link }) => (
                                                <CtaButton
                                                    key={id}
                                                    variant={type}
                                                    onClick={() => {
                                                        if (window['gtag']) {
                                                            // send google analytics event
                                                            window.gtag(
                                                                'event',
                                                                'CTA_click',
                                                                {
                                                                    event_category: type,
                                                                    event_label: id,
                                                                    value: 5,
                                                                }
                                                            )
                                                        }

                                                        scrollTo
                                                            ? scroller.scrollTo(
                                                                  scrollTo,
                                                                  {
                                                                      duration: 1200,
                                                                      delay: 0,
                                                                      smooth:
                                                                          'easeInOutQuart',
                                                                  }
                                                              )
                                                            : window.open(
                                                                  link,
                                                                  '_blank'
                                                              )
                                                    }}
                                                >
                                                    <FormattedMessage id={id} />
                                                </CtaButton>
                                            )
                                        )}
                                    </Box>
                                </Grid>
                            </Box>
                        </StyledWhiteTextBox>
                    </Grid>
                    <Grid
                        sm={12}
                        md={image ? 5 : 4}
                        item
                        style={isExtraSmall ? { width: '100%' } : undefined}
                    >
                        <Box mt={isExtraSmall ? 0 : isSmall ? 5 : 20}>
                            {image && (
                                <HeroImage
                                    purpose={image.purpose}
                                    title={image.title}
                                    size={image.size}
                                />
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </StyledContainer>

            <AnimatedWave bottom={true} />
        </StyledHero>
    )
}

export default Hero
