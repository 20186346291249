import styled, { css, keyframes } from 'styled-components'
import { PLANLY_THEME } from '../Theme/Theme'
import { MenuList } from '@material-ui/core'

export const SectionWrap = styled.main`
    min-height: 400px;
`

export const StyledHeader = styled.header`
    display: flex;
    background: rgb(248, 207, 157);
    background: linear-gradient(
        270deg,
        rgba(248, 207, 157, 1) 0%,
        rgba(250, 182, 179, 1) 35%,
        rgba(223, 106, 122, 1) 76%,
        rgba(162, 79, 116, 1) 100%
    );
    position: fixed;
    width: 100%;
    top: 0;
    box-shadow: ${(props) =>
        props.className === 'scrolled'
            ? '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);'
            : 'none'};
    background: ${(props) =>
        props.className === 'scrolled' &&
        `linear-gradient(270deg, rgba(250,182,179,1) 0%, rgba(223,106,122,1) 50%, rgba(162,79,116,1) 100%)`};

    z-index: 10;
    transition: box-shadow 400ms ease-out, background 400ms ease-in;
`

export const StyledMenuList = styled(MenuList)`
    background: #1c1338
        linear-gradient(rgba(223, 106, 122, 1), rgba(250, 182, 179, 1));
`

export const StyledHero = styled.section`
    scroll-snap-align: start;
    background: rgb(248, 207, 157);
    background: linear-gradient(
        270deg,
        rgba(248, 207, 157, 1) 0%,
        rgba(250, 182, 179, 1) 35%,
        rgba(223, 106, 122, 1) 76%,
        rgba(162, 79, 116, 1) 100%
    );
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    margin-top: 80px;
    padding-top: 10px;
    min-height: ${(props) =>
        props.className === 'hero-large' ? '600px' : '500px'};
    max-height: 720px;
    height: 100vh;

    ${PLANLY_THEME.breakpoints.down('sm')} {
        height: auto;
        max-height: inherit;
    }

    ${PLANLY_THEME.breakpoints.up('xl')} {
        max-height: 33vw;
    }
`

export const StyledHeroImage = styled.section`
    position: absolute;
    right: 0;
    bottom: 0;
    margin-left: 2rem;
    margin-bottom: 2rem;

    &.l {
        min-width: calc(200px + 55%);
    }

    &.m {
        min-width: calc(200px + 50%);
    }

    &.s {
        min-width: calc(200px + 40%);
    }

    ${PLANLY_THEME.breakpoints.down('sm')} {
        position: relative;
        margin-top: -30%;
        margin-bottom: 0;

        &.s {
            margin-left: 10%;
            margin-top: -10%;
        }
    }

    ${PLANLY_THEME.breakpoints.down('xs')} {
        margin-top: -15%;

        &.s {
            margin-top: -5%;
        }
    }
`

// ------
// wave effect
// -------
const wave = keyframes`
  0% {
    d: path("M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
  50% {
    d: path("M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
  100% {
    d: path("M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z");
  }
`

export interface WaveWrapperProps {
    bottom: boolean
}

export const WaveWrapper = styled.div<WaveWrapperProps>`
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    user-select: none;
    pointer-events: none;
    ${(props) =>
        props.bottom &&
        css`
            transform: matrix(1, 0, 0, -1, 0, 0);
        `};
` as React.FC<WaveWrapperProps>

export interface InnerWaveProps {
    layer: string
    waveOffSet: string
}

export const InnerWave = styled.div<InnerWaveProps>`
    width: 100%;
    height: 20vh;
    left: 0;
    position: absolute;
    svg {
        width: 100%;
        height: 20vh;
    }
    path {
        animation: ${wave} 15s linear infinite alternate;
    }
    top: ${(props) => props.waveOffSet};
    z-index: ${(props) => props.layer};
` as React.FC<InnerWaveProps>
