import { createMuiTheme } from '@material-ui/core'

export const PLANLY_THEME = createMuiTheme({
    palette: {
        primary: {
            main: '#AFD274',
        },
        secondary: {
            main: '#E8707D',
        },
    },
    typography: {
        h1: {
            fontFamily: 'Montserrat',
            fontSize: '2.25rem',
        },
        h2: {
            fontFamily: 'Montserrat',
            fontSize: '1.75rem',
        },
        h3: {
            fontFamily: 'Montserrat',
            fontSize: '1.25rem',
        },
        h4: {
            fontFamily: 'Montserrat',
            fontSize: '1.125rem',
        },
        h5: {
            fontFamily: 'Montserrat',
            fontSize: '1rem',
        },
        h6: {
            fontFamily: 'Montserrat',
            fontSize: '1rem',
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
})
