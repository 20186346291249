import { Container, Theme, useTheme } from '@material-ui/core'
import React, { ReactNode } from 'react'
import styled from 'styled-components'
import AnimatedWave from '../AnimatedWave/AnimatedWave'

export interface SectionProps {
    /**
     * Children to render in the section
     */
    children: NonNullable<ReactNode>

    /**
     * set background color on section
     * @default white
     */
    backgroundColor?: string

    /**
     * Wave animation effect on section
     * @default false
     */
    enableWave?: boolean

    /**
     * Place wave on bottom of section
     * @default false
     */
    waveBottom?: boolean

    /**
     * No minheight on the section
     * @default false
     */
    noMinHeight?: boolean

    /**
     * Dont flex align center stuff in the section
     * @default false
     */
    noCenter?: boolean
}

type StyledSectionProps = {
    backgroundColor?: string
    theme?: Theme
    noMinHeight?: boolean
    noCenter?: boolean
}

// styled
const StyledSection = styled.section<StyledSectionProps>`
    scroll-snap-align: start;
    display: flex;
    align-items: ${(props) => (props.noCenter ? 'flex-start' : 'center')};
    position: relative;
    height: ${(props) => (props.noMinHeight ? 'auto' : '100vh')};
    min-height: ${(props) => (props.noMinHeight ? 'auto' : '400px')};
    background: ${(props) => props.backgroundColor};

    ${(props) => props.theme.breakpoints.up('sm')} {
        min-height: ${(props) => (props.noMinHeight ? '20vh' : '70vh')};
        height: auto;
    }

    ${(props) => props.theme.breakpoints.down('sm')} {
        min-height: ${(props) => (props.noMinHeight ? '20vh' : '400px')};
        height: auto;
    }
` as React.FC<StyledSectionProps>

const StyledWrapper = styled.div<StyledSectionProps>`
    padding-top: ${(props) => (props.noMinHeight ? '20vh ' : 'none')};
    width: 100%;
    overflow-x: hidden;
`

const Section: React.FC<SectionProps> = ({
    backgroundColor = '#fff',
    children,
    enableWave = false,
    waveBottom = false,
    noMinHeight = false,
    noCenter = false,
}) => {
    const theme: Theme = useTheme()

    return (
        <StyledSection
            backgroundColor={backgroundColor}
            theme={theme}
            noMinHeight={noMinHeight}
            noCenter={noCenter}
        >
            <StyledWrapper noMinHeight={noMinHeight}>
                <Container>{children}</Container>
            </StyledWrapper>

            {enableWave && <AnimatedWave bottom={waveBottom} />}
        </StyledSection>
    )
}

export default Section
