import { Box, Button, Grid, TextField } from '@material-ui/core'
import { Field, FieldAttributes, Form, Formik } from 'formik'
import { FormattedMessage } from 'gatsby-plugin-intl'
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import * as Yup from 'yup'
import { Inquiry } from '../ContactForm'

export interface FormContainerProps {
    handleSubmit: any
}

const FormContainer: React.FC<FormContainerProps> = ({ handleSubmit }) => {
    const contactFormSchema = Yup.object().shape({
        fullName: Yup.string()
            .min(2, 'contactForm.name.validations.required')
            .required('contactForm.name.validations.required'),
        email: Yup.string()
            .email('contactForm.email.validations.notValid')
            .required('contactForm.email.validations.required'),
        message: Yup.string()
            .min(5, 'contactForm.message.validations.required')
            .required('contactForm.message.validations.required'),
        phone: Yup.string().optional(),
    })

    const initialValues: Inquiry = {
        fullName: undefined,
        email: undefined,
        message: undefined,
        phone: undefined,
    }

    return (
        <Box pt={4}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={contactFormSchema}
                render={({ submitForm, errors, touched }) => (
                    <Form noValidate autoComplete="off">
                        <Grid container spacing={5}>
                            <Grid item xs={12}>
                                <Field
                                    name="fullName"
                                    id="fullName"
                                    render={({
                                        field,
                                    }: FieldAttributes<any>) => (
                                        <TextField
                                            required
                                            inputProps={{
                                                'data-testid': 'name',
                                            }}
                                            error={
                                                !!errors.fullName &&
                                                touched.fullName
                                            }
                                            {...field}
                                            label={
                                                <FormattedMessage id="contactForm.name.label" />
                                            }
                                            fullWidth
                                            variant="outlined"
                                            helperText={
                                                !!errors.fullName &&
                                                touched.fullName && (
                                                    <FormattedMessage
                                                        id={errors.fullName}
                                                    />
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="email"
                                    id="email"
                                    render={({
                                        field,
                                    }: FieldAttributes<any>) => (
                                        <TextField
                                            required
                                            inputProps={{
                                                'data-testid': 'email',
                                            }}
                                            error={
                                                !!errors.email && touched.email
                                            }
                                            {...field}
                                            label={
                                                <FormattedMessage id="contactForm.email.label" />
                                            }
                                            fullWidth
                                            variant="outlined"
                                            placeholder="info@voorbeeld.nl"
                                            helperText={
                                                !!errors.email &&
                                                touched.email && (
                                                    <FormattedMessage
                                                        id={errors.email}
                                                    />
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="phone"
                                    id="phone"
                                    render={({
                                        field,
                                    }: FieldAttributes<any>) => (
                                        <TextField
                                            inputProps={{
                                                'data-testid': 'phone',
                                            }}
                                            {...field}
                                            label={
                                                <FormattedMessage id="contactForm.phone.label" />
                                            }
                                            fullWidth
                                            variant="outlined"
                                            placeholder="0612345678"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="message"
                                    id="message"
                                    render={({
                                        field,
                                    }: FieldAttributes<any>) => (
                                        <TextField
                                            required
                                            multiline
                                            inputProps={{
                                                'data-testid': 'message',
                                            }}
                                            rowsMax={5}
                                            rows={2}
                                            error={
                                                !!errors.message &&
                                                touched.message
                                            }
                                            {...field}
                                            label={
                                                <FormattedMessage id="contactForm.message.label" />
                                            }
                                            fullWidth
                                            variant="outlined"
                                            helperText={
                                                !!errors.message &&
                                                touched.message && (
                                                    <FormattedMessage
                                                        id={errors.message}
                                                    />
                                                )
                                            }
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="g-recaptcha-response"
                                    id="g-recaptcha-response"
                                    render={({
                                        form,
                                    }: FieldAttributes<any>) => (
                                        <ReCAPTCHA
                                            sitekey="6LeriKcZAAAAAHYVvbNbwmSV8iF5KJ8uPH3rKpCr"
                                            onChange={(
                                                token: string | null
                                            ) => {
                                                form.setFieldValue(
                                                    'g-recaptcha-response',
                                                    token
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container justify="flex-end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => submitForm()}
                                    >
                                        <FormattedMessage id="contactForm.send" />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            />
        </Box>
    )
}

export default FormContainer
