import {CssBaseline, MuiThemeProvider} from '@material-ui/core'
import React, {ReactNode} from 'react'
import '../../../static/styles/main.scss'
import {PLANLY_THEME} from '../Theme/Theme'

type LayoutProps = {
    children?: ReactNode
}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
    return (
        <MuiThemeProvider theme={PLANLY_THEME}>
            <CssBaseline/>
            {/* sticky header */}
            {props.children}
        </MuiThemeProvider>
    )
}

export default Layout
