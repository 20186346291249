import {
    Box,
    createMuiTheme,
    Grid,
    Hidden,
    Theme,
    Typography,
    useMediaQuery,
} from '@material-ui/core'
import { FormattedMessage, IntlShape, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import Logo from '../../../../../static/images/planly-logo-dark.svg'
import { PLANLY_THEME } from '../../../Theme/Theme'
import LanguageDropdown from '../LanguageDropdown/LanguageDropdown'
import { HeaderProps } from '../Header'
import { Link } from 'gatsby'

const StyledTagLine = styled(Typography)`
    margin-left: 1.5rem;
    color: rgba(255, 255, 255, 0.75);
`

const TagLine: React.FC<HeaderProps> = ({ tagLine = '' }: HeaderProps) => {
    const intl: IntlShape = useIntl()

    const theme: Theme = createMuiTheme({
        ...PLANLY_THEME,
        palette: {
            type: 'dark',
        },
    })
    // hooks
    const isExtraSmall: boolean = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <Grid container spacing={isExtraSmall ? 2 : 0} alignItems="center">
            <Grid item xs={10} sm={7} md={3} lg={2}>
                <Box pl={2}>
                    <Link to={`/${intl.locale}`}>
                        <Logo />
                    </Link>
                </Box>
            </Grid>
            <Hidden smDown>
                <Grid item sm={3} md={7} lg={8}>
                    <StyledTagLine variant="h3">
                        <FormattedMessage id={tagLine} />
                    </StyledTagLine>
                </Grid>
            </Hidden>

            <Hidden xsDown>
                <Grid item sm={3} md={2}>
                    <LanguageDropdown />
                </Grid>
            </Hidden>
        </Grid>
    )
}

export default TagLine
