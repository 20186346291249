import React from 'react'
import { InnerWave, WaveWrapper } from '../Layout/styles'

interface WaveProps {
    /**
     * Wave on top or bottom of div
     */
    bottom: boolean
}
const duration: string = '30s'

const AnimatedWave: React.FC<WaveProps> = ({ bottom = true }) => {
    return (
        <WaveWrapper bottom={bottom}>
            <InnerWave layer="1" waveOffSet="-1.5vh">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 800 338.05"
                    preserveAspectRatio="none"
                >
                    <path fill={'white'}>
                        <animate
                            attributeName="d"
                            values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                            repeatCount="indefinite"
                            dur={duration}
                        />
                    </path>
                </svg>
            </InnerWave>
            <InnerWave layer="2" waveOffSet="1.5vh">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 800 338.05"
                    preserveAspectRatio="none"
                >
                    <path fill={'rgba(255,255,255, .4)'}>
                        <animate
                            attributeName="d"
                            values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                            repeatCount="indefinite"
                            dur={duration}
                        />
                    </path>
                </svg>
            </InnerWave>
            <InnerWave layer="3" waveOffSet="0vh">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 800 338.05"
                    preserveAspectRatio="none"
                >
                    <path fill={'rgba(255,255,255, .7)'}>
                        <animate
                            attributeName="d"
                            values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                            repeatCount="indefinite"
                            dur={duration}
                        />
                    </path>
                </svg>
            </InnerWave>
        </WaveWrapper>
    )
}

export default AnimatedWave
