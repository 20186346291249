import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

interface SeoProps {
    description?: string
    lang?: string
    title: string
}

const Seo: React.FC<SeoProps> = ({ description, lang = 'en', title }) => {
    const data = useStaticQuery(graphql`
        query seoQuery {
            site {
                siteMetadata {
                    title
                    description
                    author
                    url
                    image
                }
            }
        }
    `)

    const metaDescription = description || data?.site?.siteMetadata?.description

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`${data?.site?.siteMetadata?.title} %s`}
            meta={[
                {
                    name: `google-site-verification`,
                    content: `TODO`,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: 'og:image',
                    content: data?.site?.siteMetadata?.image,
                },
                {
                    property: 'og:url',
                    content: data?.site?.siteMetadata?.url,
                },
                {
                    property: 'twitter:image',
                    content: data?.site?.siteMetadata?.image,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: data?.site?.siteMetadata?.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]}
        />
    )
}

export default Seo
