import {
    Box,
    Grid,
    Link,
    Paper,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import axios from 'axios'
import { OutboundLink } from 'gatsby-plugin-gtag'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage } from 'gatsby-plugin-intl'
import React, { useState } from 'react'
import styled from 'styled-components'
import DitpLogo from '../../../static/images/ds-logo-purple.svg'
import Section from '../Section/Section'
import FormContainer from './FormContainer/FormContainer'

export type Inquiry = {
    fullName?: string
    email?: string
    message?: string
    phone?: string
    'g-recaptcha-response'?: string
}

const ContactForm: React.FC = () => {
    const theme: Theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'))
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const StyledGrid = styled(Grid)`
        position: ${isSmall ? 'initial' : 'relative'};
        min-height: ${isSmall ? 'inherit' : '90vh'};
        width: 100%;
    `

    const StyledFormPaper = styled(Paper)`
        position: ${isSmall ? 'relative' : 'absolute'};
        z-index: 5;
        border-radius: 20px;
        width: 100%;
    `

    const data = useStaticQuery(graphql`
        query {
            customers: file(relativePath: { eq: "customers.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    const handleSubmit = (values: Inquiry, actions: any) => {
        if (!isLoading) {
            setIsLoading(true)

            axios({
                method: 'post',
                url:
                    'https://getform.io/f/c018ca2c-1eb8-4c0c-9fc6-81dafe2a6ef4',
                data: values,
            })
                .then(() => {
                    actions.setSubmitting(false)
                    setIsSubmitted(true)
                    setIsLoading(false)
                })
                .catch((r) => {
                    console.error(r)
                    actions.setSubmitting(false)
                    setIsLoading(false)
                })
        }
    }

    return (
        <Section backgroundColor="#C1B9B0" enableWave noCenter>
            <Grid container>
                <StyledGrid item sm={12} md={7}>
                    <StyledFormPaper elevation={4}>
                        <Box p={isExtraSmall ? 2 : 5}>
                            {isSubmitted ? (
                                <>
                                    <Typography
                                        variant={isExtraSmall ? 'h3' : 'h1'}
                                        component="h2"
                                        color="secondary"
                                    >
                                        <FormattedMessage id="contactForm.submitted.title" />
                                    </Typography>

                                    <Box pt={1}>
                                        <Typography variant="body1">
                                            <FormattedMessage id="contactForm.submitted.intro" />
                                        </Typography>
                                    </Box>

                                    <Box pt={8}>
                                        <Grid
                                            container
                                            direction="column"
                                            justify="center"
                                            spacing={5}
                                            alignItems="center"
                                        >
                                            <Grid item alignContent="center">
                                                <OutboundLink
                                                    href="https://www.ditpsoftware.nl"
                                                    target="_blank"
                                                    rel="noopener"
                                                >
                                                    <DitpLogo />
                                                </OutboundLink>
                                            </Grid>
                                            <Grid item alignContent="center">
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    align="center"
                                                >
                                                    <FormattedMessage id="contactForm.submitted.ditp" />{' '}
                                                    <Link
                                                        color="inherit"
                                                        target="_blank"
                                                        rel="noopener"
                                                        href="https://www.ditpsoftware.nl"
                                                    >
                                                        ditp software
                                                    </Link>
                                                    {'.'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Typography
                                        variant={isExtraSmall ? 'h2' : 'h1'}
                                        component="h2"
                                        color="textPrimary"
                                    >
                                        <FormattedMessage id="contactForm.title" />
                                    </Typography>

                                    <Box pt={1}>
                                        <Typography variant="body1">
                                            <FormattedMessage id="contactForm.intro" />
                                        </Typography>
                                    </Box>

                                    <FormContainer
                                        handleSubmit={handleSubmit}
                                    />
                                </>
                            )}
                        </Box>
                    </StyledFormPaper>
                </StyledGrid>
            </Grid>
        </Section>
    )
}

export default ContactForm
