import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { StyledHeader } from '../styles'
import TagLine from './TagLine/TagLine'

export type HeaderProps = {
    tagLine: string
}

const Header: React.FC<HeaderProps> = (
    props: HeaderProps = {
        tagLine: '',
    }
) => {
    const [scrolled, setScrolled] = useState(false)
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScrolled(window.scrollY > 50)
        })
    }, [])

    return (
        <StyledHeader className={scrolled ? 'scrolled' : undefined}>
            <Container>
                <Box pt={isSmall ? 2 : 3} pb={isSmall ? 2 : 3}>
                    <TagLine tagLine={props.tagLine} />
                </Box>
            </Container>
        </StyledHeader>
    )
}

export default Header
