import {useIntl} from 'gatsby-plugin-intl'
import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import {StyledHeroImage} from '../../styles'

export const COVID = 'covid'
export const TRAINING = 'training'
export const AFTER_SALES = 'aftersales'

export type HeroImageProps = {
    purpose: string
    title: string,
    size: 's' | 'm' | 'l'
}

const HeroImage: React.FC<HeroImageProps> = ({purpose, title, size}) => {
    const intl = useIntl()
    const data = useStaticQuery(graphql`
        query {
            covid: file(relativePath: { eq: "thuiswerken-hero.png" }) {
                ...featureImage
            }
            training: file(
                relativePath: { eq: "training-evaluatie-hero.png" }
            ) {
                ...featureImage
            }
            aftersales: file(
                relativePath: { eq: "dienstverlening-hero.png" }
            ) {
                ...featureImage
            }
        }
    `)

    return (
        <StyledHeroImage className={size}>
            <Img
                placeholderStyle={{visibility: "hidden"}}
                fluid={data[purpose].childImageSharp.fluid}
                alt={title}
                loading="eager"
                draggable={false}
                durationFadeIn={100}
                title={intl.formatMessage({
                    id: title,
                })}
            />
        </StyledHeroImage>
    )
}

export default HeroImage
