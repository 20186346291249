import { Button } from '@material-ui/core'
import React, { ReactNode } from 'react'
import styled from 'styled-components'

export enum ButtonVariants {
    PRIMARY,
    SECONDARY,
}

export interface CtaButtonProps {
    variant?: ButtonVariants
    children?: ReactNode
    onClick?: Function
}

const StyledButton = styled(Button)`
    border-radius: none;
    font-weight: 600;
    text-transform: none;
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
    margin-right: 0.75rem;
    color: white;
    background: ${(props) =>
        props.variant === 'outlined' ? 'rgba(0, 0, 0, 0.05)' : 'inherit'};
    border: ${(props) =>
        props.variant === 'outlined'
            ? '2px solid rgba(255, 255, 255, 0.6)'
            : 'inherit'};
    &:hover {
        border: ${(props) =>
            props.variant === 'outlined'
                ? '2px solid rgba(255, 255, 255, 1)'
                : 'inherit'};
    }
`

const CtaButton: React.FC<CtaButtonProps> = ({
    variant = ButtonVariants.PRIMARY,
    children,
    onClick,
}) => {
    const clickHandler = onClick
        ? onClick
        : () => {
              console.warn('Button is missing onClick declaration')
          }

    return (
        <StyledButton
            variant={
                variant === ButtonVariants.PRIMARY ? 'contained' : 'outlined'
            }
            color="primary"
            onClick={() => clickHandler()}
        >
            {children}
        </StyledButton>
    )
}

export default CtaButton
