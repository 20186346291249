import {
    Box,
    Grid,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import {
    FormattedHTMLMessage,
    FormattedMessage,
    useIntl,
} from 'gatsby-plugin-intl'
import React from 'react'
import { Element } from 'react-scroll'
import { ButtonVariants } from '../components/Button/CtaButton'
import ContactForm from '../components/ContactForm/ContactForm'
import Footer from '../components/Footer/Footer'
import Layout from '../components/Layout/Layout'
import Section from '../components/Section/Section'
import Seo from '../components/Seo/Seo'
import Header from '../components/Layout/Header/Header'
import Hero from '../components/Layout/Hero/Hero'
import { SectionWrap } from '../components/Layout/styles'
import { COVID } from '../components/Layout/Hero/HeroImage/HeroImage'

export const featureImage = graphql`
    fragment featureImage on File {
        childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid
            }
        }
    }
`

const AMOUNT_OF_FEATURES = 4

const IndexPage = () => {
    const intl = useIntl()
    const theme: Theme = useTheme()
    const isSmall: boolean = useMediaQuery(theme.breakpoints.down('sm'))

    const data = useStaticQuery(graphql`
        query {
            feat0: file(relativePath: { eq: "planly-registraties.png" }) {
                ...featureImage
            }
            feat1: file(relativePath: { eq: "planly-overview.png" }) {
                ...featureImage
            }
            feat2: file(relativePath: { eq: "planly-mailing.png" }) {
                ...featureImage
            }
            feat3: file(relativePath: { eq: "couple-evaluation.png" }) {
                ...featureImage
            }
        }
    `)

    return (
        <Layout>
            <Header tagLine="index.header.tagLine" />

            <Hero
                title="index.header.whatsPlanly.title"
                description="index.header.whatsPlanly.description"
                functionalities={[
                    'index.header.functionalities.1',
                    'index.header.functionalities.2',
                    'index.header.functionalities.3',
                ]}
                cta={[
                    {
                        id: 'index.header.cta.secondary',
                        type: ButtonVariants.SECONDARY,
                        label: 'contact form',
                        scrollTo: 'contact-form',
                    },
                ]}
                image={{
                    purpose: COVID,
                    title: 'covid.header.imageTitle',
                    size: 'l',
                }}
            />

            <FormattedMessage id="index.seoTitle">
                {(msg: string) => <Seo title={msg} />}
            </FormattedMessage>
            <SectionWrap>
                <Section>
                    <Box mt={isSmall ? 5 : 20} px={isSmall ? 0 : 3}>
                        {[...Array(AMOUNT_OF_FEATURES)].map((_, index) => (
                            <Box mb={20} key={index}>
                                <Grid
                                    container
                                    alignItems="center"
                                    direction={
                                        index % 2 === 0 ? 'row-reverse' : 'row'
                                    }
                                >
                                    <Box clone order={{ xs: 1, md: 0 }}>
                                        <Grid item sm={12} md={4}>
                                            <Img
                                                css={
                                                    isSmall
                                                        ? 'min-width:calc(100vw - 2rem);'
                                                        : undefined
                                                }
                                                fluid={
                                                    data[`feat${index}`]
                                                        .childImageSharp.fluid
                                                }
                                                alt="askly"
                                                title={intl.formatMessage({
                                                    id: `index.features.${index}.imageTitle`,
                                                })}
                                            />
                                        </Grid>
                                    </Box>
                                    <Grid item sm={12} md={8}>
                                        <Box
                                            px={isSmall ? 1 : 12}
                                            pb={isSmall ? 4 : 0}
                                        >
                                            <Typography
                                                variant={isSmall ? 'h2' : 'h1'}
                                                component="h2"
                                                gutterBottom
                                                color="primary"
                                            >
                                                <FormattedMessage
                                                    id={`index.features.${index}.title`}
                                                />
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                <FormattedHTMLMessage
                                                    id={`index.features.${index}.description`}
                                                />
                                            </Typography>

                                            {index === 3 && (
                                                <ul>
                                                    <li>
                                                        <Typography variant="body1">
                                                            <FormattedHTMLMessage
                                                                id={`index.features.${index}.list.1`}
                                                            />
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography variant="body1">
                                                            <FormattedHTMLMessage
                                                                id={`index.features.${index}.list.2`}
                                                            />
                                                        </Typography>
                                                    </li>
                                                    <li>
                                                        <Typography variant="body1">
                                                            <FormattedHTMLMessage
                                                                id={`index.features.${index}.list.3`}
                                                            />
                                                        </Typography>
                                                    </li>
                                                </ul>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Box>
                </Section>
            </SectionWrap>

            <Element name="contact-form">
                <a id="contact-form">
                    <ContactForm />
                </a>
            </Element>

            <Footer />
        </Layout>
    )
}

// @ts-ignore
export default IndexPage
