import {
    Box,
    Container,
    Divider,
    Grid,
    Link,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core'
import { FormattedMessage } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'
import DitpLogo from '../../../static/images/ds-logo-white.svg'

const StyledFooter = styled.footer`
    background: #7d695a;
    color: white;
`
const WhiteDivider = styled(Divider)`
    background-color: rgba(255, 255, 255, 0.12);
`

const WhiteTypography = styled(Typography)`
    color: white;
`

const StyledAddress = styled.address`
    font-style: normal;
`

const Footer: React.FC = () => {
    const theme: Theme = useTheme()
    const isExtraSmall: boolean = useMediaQuery(theme.breakpoints.down('xs'))

    return (
        <StyledFooter>
            <Container>
                <Box py={10}>
                    <Grid
                        container
                        direction={isExtraSmall ? 'column' : 'row'}
                        justify="space-between"
                        alignItems={isExtraSmall ? 'flex-start' : 'center'}
                        spacing={3}
                    >
                        <Grid item xs={12} sm={2}>
                            <Link
                                color="inherit"
                                target="_blank"
                                rel="noopener"
                                href="https://www.ditpsoftware.nl"
                            >
                                <DitpLogo css="min-width:100px;" />
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography variant="h3" component="h3">
                                <FormattedMessage id="footer.phone" />
                            </Typography>
                            <Box mt={3}>
                                <Typography variant="body1">
                                    <Link
                                        color="inherit"
                                        href="tel:0031850600967"
                                    >
                                        (+31) 85 0600 967
                                    </Link>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography variant="h3" component="h3">
                                <FormattedMessage id="footer.email" />
                            </Typography>
                            <Box mt={3}>
                                <Typography variant="body1">
                                    info@ditpsoftware.nl
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Typography variant="h3" component="h3">
                                <FormattedMessage id="footer.address" />
                            </Typography>

                            <Box mt={3}>
                                <StyledAddress>
                                    <Typography variant="body1">
                                        Hendrik Figeeweg 1-W, 2031 BJ, HAARLEM
                                    </Typography>
                                </StyledAddress>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <WhiteDivider variant="middle" color="#fff" />

                <Box py={3}>
                    <WhiteTypography
                        variant="body2"
                        color="textSecondary"
                        align="center"
                    >
                        {'Copyright © '}
                        <Link
                            color="inherit"
                            target="_blank"
                            rel="noopener"
                            href="https://www.ditpsoftware.nl"
                        >
                            ditp software
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'. '}
                        <FormattedMessage id="footer.poweredBy" />
                    </WhiteTypography>
                </Box>
            </Container>
        </StyledFooter>
    )
}

export default Footer
